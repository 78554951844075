.asToastRoot {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
}

/* 遮罩层 */
.asToastOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* 弹窗容器 */
.asToastContent {
  position: fixed;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7);
  /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); */
  width: 240px;
  padding: 20px;
  z-index: 1001;
  border-radius: 12px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  overflow: hidden;
}

.global-toast.fade-out {
  opacity: 0; /* 设置渐隐至完全透明 */
}

/* 弹窗文字 */
.asToastContentText {
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  margin-top: 12px;
}

/* 关闭按钮 */
.asToastContent button {
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.asToastContent button:hover {
  background-color: #0056b3;
}

.asToastIcon {
  width: 32px;
  height: 32px;
  object-fit: contain;
}
