.shapeButtonStrokeBlack {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: row;
    overflow: hidden;
    box-sizing: border-box;
    height: 54px;
    border-radius: 27px;
    border: 1px solid #000000;
    color: #000000;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
}

.shapeButtonFillBlue {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: row;
    overflow: hidden;
    box-sizing: border-box;
    height: 54px;
    border-radius: 27px;
    background-color: #3B7EEE;
    border: 1px solid #3B7EEE;
    color: #FFFFFF;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
}

.shapeButtonFillDisable {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: row;
    overflow: hidden;
    box-sizing: border-box;
    height: 54px;
    border-radius: 27px;
    background-color: #F6F6F6;
    border: 1px solid #F6F6F6;
    color: #999999;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
}

.shapeButtonFillGreen {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: row;
    overflow: hidden;
    box-sizing: border-box;
    height: 54px;
    border-radius: 27px;
    background-color: #64ca75;
    border: 1px solid #64ca75;
    color: #FFFFFF;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
}

.shapeButtonFillLightRed {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: row;
    overflow: hidden;
    box-sizing: border-box;
    height: 54px;
    border-radius: 27px;
    background-color: #FFDDDD;
    border: 1px solid #FFDDDD;
    color: #FF5B5B;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
}

.shapeBorderSelect {
    border: 2px solid #3B7EEE !important;
    background-color: #DFF4FF !important;
}

.shapeBorderUnSelect {
    border: 2px solid #F6F6F6 !important;
    background-color: #F6F6F6 !important;
}

.shapeBorderBlue {
    border: 1px solid #3B7EEE !important;
}

.shapeTransparent {
    border: 1px solid transparent !important;
    background-color: transparent !important;
}

.shapeSegmentBlue {
    border: 1px solid #E2E9F5 !important;
    background-color: #E2E9F5 !important;
}


.shapeGray {
    border: 1px solid #CFCFCF !important;
    background-color: #CFCFCF !important;
}

.shapeBorderNone {
    border: none !important;
}

.shapeSelfieR75 {
    border-radius: 75px !important;
    border: 2px solid #3B7EEE;
}

.shapeR28 {
    border-radius: 28px !important;
}

.shapeR25 {
    border-radius: 25px !important;
}

.shapeR24 {
    border-radius: 24px !important;
}

.shapeR19 {
    border-radius: 19px !important;
}

.shapeR17 {
    border-radius: 17px !important;
}

.shapeR16 {
    border-radius: 16px !important;
}

.shapeR12 {
    border-radius: 12px !important;
}

.shapeR8 {
    border-radius: 8px !important;
}

.shapeR6 {
    border-radius: 6px !important;
}

.shapeR4 {
    border-radius: 4px !important;
}

.shapePageColor {
    background-color: #F1F3F5 !important;
}

.shapePageColorWhite {
    background-color: #FFFFFF !important;
}

.shapeBlue {
    background-color: #3B7EEE !important;
}

.shapeWhite {
    background-color: #ffffff !important;
}

.shapeLightOrange {
    background-color: #FFF4DE !important;
}

.shapeBlueF2F6FF {
    background-color: #F2F6FF !important;
}

.shapeColorFFF7E7 {
    background-color: #FFF7E7 !important;
}

.shapeColorFF8F28 {
    background-color: #FF8F28 !important;
}

.shapeColorFAF9F5 {
    background-color: #FAF9F5 !important;
}

.shapeColorE7F8FF {
    background-color: #E7F8FF !important;
}

.shapeColorFF8316 {
    background-color: #FF8316 !important;
}

.shapeColorF4FDFF {
    background-color: #F4FDFF !important;
}

.shapeColorFFF4DE {
    background-color: #FFF4DE !important;
}

.shapeColor267AF7 {
    background-color: #267AF7 !important;
}

.shapeColorDDDEE0 {
    background-color: #DDDEE0 !important;
}

.shapeWhiteR6 {
    background-color: #ffffff !important;
    border-radius: 6px !important;
}

.shapeWhiteR12 {
    background-color: #ffffff !important;
    border-radius: 12px !important;
}

.shapeWhiteR16 {
    background-color: #ffffff !important;
    border-radius: 16px !important;
}

.shapeWhiteR22 {
    background-color: #ffffff !important;
    border-radius: 22px !important;
}

.shapeWhiteR24 {
    background-color: #ffffff !important;
    border-radius: 24px !important;
}

.shapeWhiteR28 {
    background-color: #ffffff !important;
    border-radius: 28px;
}

.shapeBlueR28 {
    background-color: #3B7EEE !important;
    border-radius: 28px;
}

.shapeBlueR25 {
    background-color: #3B7EEE !important;
    border-radius: 25px;
}

.shapeBlueR22 {
    background-color: #3B7EEE !important;
    border-radius: 22px;
}

.shapeBlueR18 {
    background-color: #3B7EEE !important;
    border-radius: 18px;
}

.shapeBlueR16 {
    background-color: #3B7EEE !important;
    border-radius: 16px;
}

.shapeStepHeader {
    background-color: #E2EBE7;
    border-radius: 4px;
}

.shapeStepHeaderHL {
    background-color: #A8B2B8;
    border-radius: 4px;
}

.shapeDisableR28 {
    background-color: #DFE4EC;
    border-radius: 28px;
}

.shapeClear {
    background-color: rgba(255, 255, 255, 0) !important;
}

.shapeShadowGray {
    box-shadow: 0px 4px 8px rgba(0, 30, 76, 0.1);
    /* 调整水平位移、垂直位移、模糊半径和颜色 */
}

.shapeShadowBlue {
    box-shadow: 0px 4px 8px rgba(28, 19, 85, 0.25);
    /* 调整水平位移、垂直位移、模糊半径和颜色 */
}

.shapeBorderFF8F28 {
    border: 1px solid #FF8F28 !important;
}

.shapeStepRemainR22 {
    background-color: #F6F6F6 !important;
    border-radius: 22px !important;
    box-shadow: 0px 4px 8px rgba(44, 43, 112, 0.05);
}

.shapeStepFormR25 {
    background-color: #F6F6F6 !important;
    border-radius: 25px !important;
    border: none;
}

.shapeDocTypeSelected {
    border: 1px solid #3B7EEE;
    background-color: #E8F4FF !important;
    border-radius: 12px !important;
}

.shapeDocTypeUnSelect {
    border: 1px solid #F8F8F8;
    background-color: #F8F8F8 !important;
    border-radius: 12px !important;
}

.shapeLineGray {
    border-top: 1px dashed #E1E1E1;
    border-color: #E1E1E1;
    width: 100%;
    margin: 10px 0;
    border-width: 1px;
    border-top-style: dashed;
    border-top-width: 1px;
    border-spacing: 4px;
}

.shapeLineBlue {
    border-top: 1px dashed #3B7EEE;
    border-color: #3B7EEE;
    width: 100%;
    margin: 10px 0;
    box-sizing: border-box;
    overflow: hidden;
    border-width: 1px;
    border-top-style: dashed;
    border-top-width: 1px;
    border-spacing: 4px !important;
}

.shapeCardFillItem {
    position: relative;
    display: flex;
    width: 100%;
    height: 50px;
    padding: 0px 12px;
    justify-content: center;
    align-content: flex-start;
    flex-direction: row;
    overflow: hidden;
    box-sizing: border-box;
    border-radius: 25px;
    background-color: #F6F6F6;
    border: 1px solid #F6F6F6;
}

.shapeCardFillItemError {
    position: relative;
    display: flex;
    width: 100%;
    height: 50px;
    padding: 0px 12px;
    justify-content: center;
    align-content: flex-start;
    flex-direction: row;
    overflow: hidden;
    box-sizing: border-box;
    border-radius: 25px;
    background-color: #FFF0F0;
    border: 1px solid #F55555;
}

.shapeCardWhiteR16 {
    position: relative;
    display: flex;
    width: 100%;
    padding: 16px;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    overflow: hidden;
    box-sizing: border-box;
    border-radius: 16px;
    background-color: #FFFFFF;
    border: 1px solid #FFFFFF;
}

.shapeCardWhiteR12 {
    position: relative;
    display: flex;
    width: 100%;
    padding: 12px;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    overflow: hidden;
    box-sizing: border-box;
    border-radius: 12px;
    background-color: #FFFFFF;
    border: 1px solid #FFFFFF;
}

.shapeNavTopBg {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 222px;
    background-color: #3B7EEE;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.shapeNavTopBgLarge {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 236px;
    background-color: #3B7EEE;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.shapeNavTopBgSmall {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 172px;
    background-color: #3B7EEE;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.shapePinStatusBillBg {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 16px 16px;
    box-sizing: border-box;
    overflow: hidden;
    background-color: #F9FAFC;
    border: 1px solid #C9C9C9;
    border-radius: 12px;
}

.shapeWalletTipsR12 {
    background-color: #FFF4DE !important;
    border-radius: 12px !important;
}

.shapeBorderDashLineBlue {
    border: 1px dashed #3B7EEE;
}

.shapeBorderD7E8FF {
    border: 2px solid #D7E8FF;
}

.shapeBorderE7E7E7 {
    border: 1px solid #E7E7E7;
}

.shapeBorderC0F1D2 {
    border: 1px solid #C0F1D2;
}

.shapeBorder202124 {
    border: 1px solid #202124;
}

.shapeBorderFFECD7 {
    border: 1px solid #FFECD7 !important;
}

.shapeBorder267AF7 {
    border: 2px solid #267AF7 !important;
}

.shapeF4FDFF {
    background-color: #F4FDFF !important;
}

.shapeF9F9F9 {
    background-color: #F9F9F9 !important;
}

.shapeF0FBF5 {
    background-color: #F0FBF5 !important;
}

.shapeFFFDF4 {
    background-color: #FFFDF4 !important;
}