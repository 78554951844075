.layoutPageRootWhite {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
  box-sizing: border-box;
  background-color: #ffffff;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.layoutPageRootGray {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
  box-sizing: border-box;
  background-color: #F1F3F5;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.layoutRowCenterW100 {
  position: relative !important;
  display: flex !important;
  width: 100% !important;
  justify-content: center !important;
  align-items: center !important;
  overflow: hidden !important;
  box-sizing: border-box !important;
  flex-wrap: wrap !important;
}

.layoutRowCenter {
  position: relative !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  overflow: hidden !important;
  box-sizing: border-box !important;
  flex-wrap: wrap !important;
}

.layoutRowTopW100 {
  position: relative !important;
  display: flex !important;
  width: 100% !important;
  justify-content: center !important;
  align-items: flex-start !important;
  overflow: hidden !important;
  box-sizing: border-box !important;
  flex-wrap: wrap !important;
}

.layoutRowStart {
  position: relative !important;
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  overflow: hidden !important;
  box-sizing: border-box !important;
  flex-wrap: wrap !important;
}

.layoutRowEnd {
  position: relative !important;
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
  overflow: hidden !important;
  box-sizing: border-box !important;
  flex-wrap: wrap !important;
}

.layoutRowStartW100 {
  position: relative !important;
  display: flex !important;
  width: 100% !important;
  justify-content: flex-start !important;
  align-items: center !important;
  overflow: hidden !important;
  box-sizing: border-box !important;
  flex-wrap: wrap !important;
}

.layoutRowStartW50 {
  position: relative !important;
  display: flex !important;
  width: 50% !important;
  justify-content: flex-start !important;
  align-items: center !important;
  overflow: hidden !important;
  box-sizing: border-box !important;
  flex-wrap: wrap !important;
}

.layoutRowStartFullFlex {
  position: relative !important;
  display: flex !important;
  flex: 1 !important;
  height: 100% !important;
  justify-content: flex-start !important;
  align-items: center !important;
  overflow: hidden !important;
  box-sizing: border-box !important;
  flex-wrap: wrap !important;
}

.layoutRowCenterFullFlex {
  position: relative !important;
  display: flex !important;
  flex: 1 !important;
  justify-content: center !important;
  align-items: center !important;
  overflow: hidden !important;
  box-sizing: border-box !important;
  flex-wrap: wrap !important;
}

.layoutRowEndFullFlex {
  position: relative !important;
  display: flex !important;
  flex: 1 !important;
  height: 100% !important;
  justify-content: flex-end !important;
  align-items: center !important;
  overflow: hidden !important;
  box-sizing: border-box !important;
  flex-wrap: wrap !important;
}

.layoutColumnStartFullFlex {
  position: relative !important;
  display: flex !important;
  flex-direction: column;
  flex: 1 !important;
  height: 100% !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: flex-start !important;
  overflow: hidden !important;
  box-sizing: border-box !important;
}


.layoutColumnStartW100 {
  position: relative !important;
  display: flex !important;
  flex-direction: column !important;
  width: 100% !important;
  flex-direction: column !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  overflow: hidden !important;
  box-sizing: border-box !important;
}

.layoutColumnStart {
  position: relative !important;
  display: flex !important;
  flex-direction: column;
  /* height: 100% !important; */
  flex-direction: column !important;
  justify-content: center !important;
  align-items: flex-start !important;
  overflow: hidden !important;
  box-sizing: border-box !important;
}

.layoutColumnCenter {
  position: relative !important;
  display: flex !important;
  flex-direction: column;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  overflow: hidden !important;
  box-sizing: border-box !important;
}

.layoutColumnEnd {
  position: relative !important;
  display: flex !important;
  flex-direction: column;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: flex-end !important;
  overflow: hidden !important;
  box-sizing: border-box !important;
}

.layoutColumnCenterW100 {
  position: relative !important;
  display: flex !important;
  width: 100% !important;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
  overflow: hidden !important;
  box-sizing: border-box !important;
}

.layoutColumnHCenterW100 {
  position: relative !important;
  display: flex !important;
  width: 100% !important;
  flex-direction: column;
  justify-content: flex-start !important;
  align-items: center !important;
  overflow: hidden !important;
  box-sizing: border-box !important;
}


.layoutColumnHCenterW100H100 {
  position: relative !important;
  display: flex !important;
  width: 100% !important;
  height: 100% !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  overflow: hidden !important;
  box-sizing: border-box !important;
}

.layoutColumnCenterH100FullFlex {
  position: relative !important;
  display: flex !important;
  flex: 1 !important;
  height: 100% !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  overflow: hidden !important;
  box-sizing: border-box !important;
}

.layoutColumnCenterH100 {
  position: relative !important;
  display: flex !important;
  height: 100% !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  overflow: hidden !important;
  box-sizing: border-box !important;
}

.layoutColumnCenterW50H100 {
  position: relative !important;
  display: flex !important;
  flex: 1 !important;
  height: 100% !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  overflow: hidden !important;
  box-sizing: border-box !important;
}

.layoutColumnHCenterW50H100 {
  position: relative !important;
  display: flex !important;
  flex: 1 !important;
  height: 100% !important;
  flex-direction: column !important;
  justify-content: flex-start !important;
  align-items: center !important;
  overflow: hidden !important;
  box-sizing: border-box !important;
}

.layoutFlow {
  display: flex !important;
  flex-wrap: wrap !important;
  gap: 16px !important;
  width: 100% !important;
  overflow: hidden !important;
  box-sizing: border-box !important;
}

.layoutFlowGap0 {
  display: flex !important;
  flex-wrap: wrap !important;
  gap: 0px !important;
  width: 100% !important;
  overflow: hidden !important;
  box-sizing: border-box !important;
}

.layoutFlowGap8 {
  display: flex !important;
  flex-wrap: wrap !important;
  gap: 8px !important;
  width: 100% !important;
  overflow: hidden !important;
  box-sizing: border-box !important;
}

.layoutFlowGap16 {
  display: flex !important;
  flex-wrap: wrap !important;
  gap: 16px !important;
  width: 100% !important;
  overflow: hidden !important;
  box-sizing: border-box !important;
}

.layoutFlowGap24 {
  display: flex !important;
  flex-wrap: wrap !important;
  gap: 24px !important;
  width: 100% !important;
  overflow: hidden !important;
  box-sizing: border-box !important;
}

.layoutFlowGap30 {
  display: flex !important;
  flex-wrap: wrap !important;
  gap: 30px !important;
  width: 100% !important;
  overflow: hidden !important;
  box-sizing: border-box !important;
}


.layoutFlowFullFlex {
  display: flex !important;
  flex-wrap: wrap !important;
  gap: 10px !important;
  flex: 1 !important;
  overflow: hidden !important;
  box-sizing: border-box !important;
}

.layoutFlowFullFlexItem {
  position: relative !important;
  display: flex !important;
  flex: 1;
  flex-direction: column;
  box-sizing: border-box !important;
  overflow: hidden !important;
  justify-content: center;
  align-items: center;
}

.layoutFlowItem {
  display: flex !important;
  /* flex: 1 1 200px !important; */
  /* max-width: 300px !important; */
  box-sizing: border-box !important;
  overflow: hidden !important;
  justify-content: center;
  align-items: center;
}

.layoutTopFixed {
  position: fixed !important;
  z-index: 200;
  top: 0px !important;
  transform: translateZ(0) !important;
}

.layoutHeight180 {
  height: 180px !important;
}

.layoutHeight160 {
  height: 160px !important;
}

.layoutHeight144 {
  height: 144px !important;
}

.layoutHeight140 {
  height: 140px !important;
}

.layoutHeight130 {
  height: 130px !important;
}

.layoutHeight120 {
  height: 120px !important;
}

.layoutHeight110 {
  height: 110px !important;
}

.layoutHeight100 {
  height: 100px !important;
}

.layoutHeight90 {
  height: 90px !important;
}

.layoutHeight86 {
  height: 86px !important;
}

.layoutHeight80 {
  height: 80px !important;
}

.layoutHeight76 {
  height: 76px !important;
}

.layoutHeight70 {
  height: 70px !important;
}

.layoutHeight68 {
  height: 68px !important;
}

.layoutHeight66 {
  height: 66px !important;
}

.layoutHeight60 {
  height: 60px !important;
}

.layoutHeight56 {
  height: 56px !important;
}

.layoutHeight50 {
  height: 50px !important;
}

.layoutHeight48 {
  height: 48px !important;
}

.layoutHeight44 {
  height: 44px !important;
}

.layoutHeight40 {
  height: 40px !important;
}

.layoutHeight36 {
  height: 36px !important;
}

.layoutHeight32 {
  height: 32px !important;
}

.layoutHeight10 {
  height: 10px !important;
}

.layoutHeight8 {
  height: 8px !important;
}

.layoutHeight2 {
  height: 2px !important;
}

.layoutWidth42 {
  width: 42px !important;
}

.layoutWidth12 {
  width: 12px !important;
}

.layoutWidth10 {
  width: 10px !important;
}

.layoutWidth2 {
  width: 2px !important;
}

.layoutDisable {
  pointer-events: none;
}

.layoutEnable {
  pointer-events: auto;
}

/*********Icon***********/
.layoutIconAsBackground {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.layoutIconSize150 {
  width: 150px;
  height: 150px;
}

.layoutIconSize108 {
  width: 108px;
  height: 108px;
}

.layoutIconSize90 {
  width: 90px;
  height: 90px;
}

.layoutIconSize84 {
  width: 84px;
  height: 84px;
}

.layoutIconSize80 {
  width: 80px;
  height: 80px;
}

.layoutIconSize72 {
  width: 72px;
  height: 72px;
}

.layoutIconSize64 {
  width: 64px;
  height: 64px;
}

.layoutIconSize60 {
  width: 60px;
  height: 60px;
}

.layoutIconSize50 {
  width: 50px;
  height: 50px;
}

.layoutIconSize44 {
  width: 44px;
  height: 44px;
}

.layoutIconSize40 {
  width: 40px;
  height: 40px;
}

.layoutIconSize38 {
  width: 38px;
  height: 38px;
}

.layoutIconSize34 {
  width: 34px;
  height: 34px;
}

.layoutIconSize30 {
  width: 30px;
  height: 30px;
}

.layoutIconSize28 {
  width: 28px;
  height: 28px;
}

.layoutIconSize24 {
  width: 24px;
  height: 24px;
}

.layoutIconSize22 {
  width: 22px;
  height: 22px;
}

.layoutIconSize20 {
  width: 20px;
  height: 20px;
}

.layoutIconSize18 {
  width: 18px;
  height: 18px;
}

.layoutIconSize16 {
  width: 16px;
  height: 16px;
}

.layoutIconSize14 {
  width: 14px;
  height: 14px;
}

.layoutIconSize12 {
  width: 12px;
  height: 12px;
}

.layoutIconSize6 {
  width: 6px;
  height: 6px;
}

.layoutIconSize8 {
  width: 8px;
  height: 8px;
}

.layoutIconW25H16 {
  width: 25px;
  height: 16px;
}

.layoutIconW117H73 {
  width: 117px;
  height: 73px;
  object-fit: contain;
}

.layoutIconW42H50 {
  width: 42px;
  height: 50px;
  object-fit: fill !important;
}

/*********z-ndex***********/
.layoutZIndexHightest {
  z-index: 999 !important;
}

/*********Position***********/
.layoutPositionAbs {
  position: absolute !important;
}

.layoutPositionAbsStepFormClickSpace {
  position: absolute !important;
  height: 100% !important;
  top: 0px !important;
  bottom: 0px !important;
  right: 0px !important;
  left: 0px;
  bottom: 0px;
  z-index: 12;
}

.layoutPositionAbsGoodUserTrackerMinutes {
  position: absolute !important;
  bottom: 0px !important;
  left: 50% !important;
  margin-left: 82px !important;
  bottom: 0px;
  font-size: 14px;
  font-weight: bold;
  color: #FF8316;
  z-index: 12;
}

.layoutPositionAbsStepFormExt {
  position: absolute !important;
  height: 100% !important;
  top: 0px !important;
  bottom: 0px !important;
  right: 12px !important;
  z-index: 10;
  object-fit: contain;
}

.layoutPositionAbsAlignBottom {
  position: absolute !important;
  bottom: 0px !important;
  right: 0px !important;
  object-fit: contain;
}

.layoutAbsAlignTopRight {
  position: absolute !important;
  top: 0px !important;
  right: 0px !important;
  object-fit: fill;
}

.layoutAbsRight14 {
  right: 14px !important;
}

.layoutAbsFull {
  position: absolute !important;
  left: 0px !important;
  top: 0px !important;
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.layoutPositionFixed {
  position: fixed !important;
}

.layoutPositionRelative {
  position: relative !important;
}
