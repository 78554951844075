.textNavBar {
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    width: 100%;
    white-space: pre-line !important;
}

.textHeader {
    color: #000000 !important;
    font-size: 24px !important;
    font-weight: bold !important;
    text-align: left;
    white-space: pre-line !important;
    z-index: 2;
}

.textTitle {
    color: #000000 !important;
    font-size: 16px !important;
    font-weight: bold !important;
    text-align: left;
    white-space: pre-line !important;
    z-index: 2;
}

.textSubTitle {
    color: #333333;
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    white-space: pre-line !important;
    z-index: 2;
}

.textDetail {
    color: #999999 !important;
    font-size: 14px !important;
    text-align: left;
    white-space: pre-line !important;
    z-index: 2;
    font-weight: 500 !important;
}

.textSmallDetail {
    color: #8F97A9 !important;
    font-size: 12px !important;
    text-align: left;
    white-space: pre-line !important;
    z-index: 2;
    font-weight: 500 !important;
}

.textBold {
    text-align: left;
    white-space: pre-line !important;
    z-index: 2;
    font-weight: bold !important;
}

.textNormal {
    text-align: left;
    white-space: pre-line !important;
    z-index: 2;
    font-weight: 500 !important;
}

.textLighter {
    text-align: left;
    white-space: pre-line !important;
    z-index: 2;
    font-weight: 400 !important;
}

.textDetail h3 {
    color: black;
    font-weight: bold;
}

/***************Text Size*****************/
.textSize30 {
    font-size: 30px !important;
}

.textSize24 {
    font-size: 24px !important;
}

.textSize22 {
    font-size: 22px !important;
}

.textSize20 {
    font-size: 20px !important;
}

.textSize18 {
    font-size: 18px !important;
}

.textSize16 {
    font-size: 16px !important;
}

.textSize14 {
    font-size: 14px !important;
}

.textSize12 {
    font-size: 12px !important;
}

.textSize11 {
    font-size: 11px !important;
}

.textSize10 {
    font-size: 10px !important;
}



/***************Text Color*****************/
.textColorClear {
    color: rgba(255, 255, 255, 0) !important;
}

.textColorBlue {
    color: #3B7EEE !important;
}

.textColorLightBlue {
    color: #76A5F5 !important;
}

.textColorBlack {
    color: #000000 !important;
}

.textColorLightBlack {
    color: #666666 !important;
}

.textColorLightGray {
    color: #aaaaaa !important;
}

.textColorLessGray {
    color: #E2E2E2 !important;
}


.textColorWhite {
    color: #ffffff !important;
}

.textColorGreen {
    color: #64ca75 !important;
}

.textColorRed {
    color: #FF5B5B !important;
}

.textColorErrorRed {
    color: #FE6F48 !important;
}

.textColorLightRed {
    color: #FFDDDD !important;
}

.textColorOrange {
    color: #FF7A00 !important;
}

.textColorBoldOrange {
    color: #FF5E2C !important;
}

.textColorDetail {
    color: #999999 !important;
}

.textColorUnSelect {
    color: #ADB4CC !important;
}

.textColorStepRemain {
    color: #76A5F5 !important;
}

.textColorStepFormTitle {
    color: #777D90 !important;
}

.textColorDisplayWalletAccount {
    color: #B0B1D3 !important;
}

.textColorFF8F28 {
    color: #FF8F28 !important;
}

.textColor054385 {
    color: #054385 !important;
}

.textColor333333 {
    color: #333333 !important;
}

.textColor716C5B {
    color: #716C5B !important;
}

.textColor5B747F {
    color: #5B747F !important;
}

.textColorFFA009 {
    color: #FFA009 !important;
}

.textColorFF8000 {
    color: #FF8000 !important;
}

.textColor496584 {
    color: #496584 !important;
}

.textColor0A2A4C {
    color: #0A2A4C !important;
}

.textColor00CB48 {
    color: #00CB48 !important;
}

.textColor87796A {
    color: #87796A !important;
}

.textColor202124 {
    color: #202124 !important;
}

.textColor01875F {
    color: #01875F !important;
}

.textColor5F6368 {
    color: #5F6368 !important;
}

.textColorEEEEEE {
    color: #eeeeee !important;
}

.textColorF22424 {
    color: #F22424 !important;
}

.textColor2F58FF {
    color: #2F58FF !important;
}

.textColor267AF7 {
    color: #267AF7 !important;
}


/***************Text Align*****************/
.textAlignCenter {
    text-align: center !important;
}

.textAlignRight {
    background-color: #00CB48;
    text-align: right !important;
}

/***************Text Weight*****************/
.textWeightBold {
    font-weight: bold !important;
}

.textWeightNormal {
    font-weight: normal !important;
}


/***************Text Decoration*****************/
.textUnderLine {
    text-decoration: underline !important;
}