input {
  outline: none;
}

input::placeholder {
  color: #c4c4c4;
}

* {
  touch-action: manipulation;
}

.AppRoot {
  display: flex;
  width: 100%;
  /* height: inherit; */
  overflow: hidden;
  box-sizing: border-box;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #F8F9F9;
}

.App {
  z-index: 1000;
  display: flex;
  overflow: hidden;
  box-sizing: border-box;
  min-height: 100vh;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: #FFF;
}

/* 媒体查询：在小屏幕下应用不同的样式 */
@media screen and (min-width: 770px) {
  .App {
    z-index: 100;
    width: 393px;
    min-height: 100vh;
    display: -webkit-flex flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    box-sizing: border-box;
    align-items: flex-start;
    background-color: #FFF;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  animation: spin 0.8s linear infinite;
}
