/* 遮罩层 */
.asLoadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 1;
  transition: opacity .3s ease;
}

.asLoadingFadeIn {
  animation: fadeIn .3s forwards;
}

.asLoadingFadeOut {
  animation: fadeOut .3s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

/* 弹窗容器 */
.asLoadingContent {
  z-index: 1001;
  position: fixed;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 120px;
  height: 120px;
  border-radius: 12px;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  box-sizing: border-box;
  overflow: hidden;
}

/* 弹窗文字 */
.asLoadingContentText {
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  margin-top: 12px;
}

.asLoadingIcon {
  width: 70px;
  height: 70px;
  object-fit: contain;
}