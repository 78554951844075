.copyInstallLinkDialogOverlayBg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    opacity: 1;
    transition: opacity .3s ease;
    padding: 16px !important;
    overflow: hidden !important;
    box-sizing: border-box !important;
}

.copyInstallLinkDialogFadeIn {
    animation: fadeIn .3s forwards;
}

.copyInstallLinkDialogFadeOut {
    animation: fadeOut .3s forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}


.copyInstallLinkDialogOverlayContent {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: white;
    border-radius: 10px;
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 20px;
    overflow: hidden;
    box-sizing: border-box;
}

/* 媒体查询：在小屏幕下应用不同的样式 */
@media screen and (min-width: 770px) {
    .copyInstallLinkDialogOverlayContent {
        z-index: 100;
        position: relative;
        display: -webkit-flex flex;
        width: 393px;
        height: 400px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: white;
        border-radius: 10px;
        padding: 16px !important;
        overflow: hidden;
        box-sizing: border-box;
    }
}


.copyInstallLinkDialogTitleRow {
    position: relative;
    display: flex;
    width: 100%;
    margin-top: 24px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    box-sizing: border-box;
}

.copyInstallLinkDialogTitleLogo {
    width: 64px;
    height: 64px;
    border-radius: 12px;
    object-fit: contain;
}

.copyInstallLinkDialogCloseIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 24px;
    height: 24px;
}

.copyInstallLinkDialogTitle {
    width: 100%;
    margin-top: 16px;
    text-align: center;
    font-size: 16px;
    color: #040607;
    white-space: pre-wrap;
    box-sizing: border-box;
    overflow: hidden;
}

.copyInstallLinkDialogDetail {
    width: inherit;
    margin-top: 24px;
    font-size: 16px;
    padding: 0px 12px;
    color: #040607;
    white-space: pre-wrap;
    box-sizing: border-box;
    overflow: hidden;
}

.copyInstallLinkDialogTitleBold {
    color: #040607;
    font-weight: bold !important;
}

.copyInstallLinkDialogTips {
    display: flex;
    width: inherit;
    margin-top: 12px;
    padding: 0px 12px;
    justify-content: center;
    align-items: flex-start;
    font-size: 12px;
    color: #858381;
    white-space: pre-wrap;
    overflow: hidden;
    box-sizing: border-box;
}

.copyInstallLinkDialogCopyContent {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 14px;
    border: 1px solid #B7DCF8;
    background-color: #E5F3FC;
    border-radius: 12px;
    overflow: hidden;
    box-sizing: border-box;
    margin-top: 20px;
}

.copyInstallLinkDialogCopyLink {
    color: #0082E6;
    font-size: 12px;
    text-align: left;
    min-height: 48px;
    text-decoration: underline;
    width: calc(100% - 47px);
    word-break: break-all;
    white-space: pre-wrap;
    /* 让文本根据需要自动换行 */
    overflow: hidden;
    /* 如果超出容器范围，隐藏溢出部分 */
    box-sizing: border-box;
    /* 计算盒模型包含边框和填充 */
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}

.copyInstallLinkDialogCopyIcon {
    position: absolute;
    top: 50%;
    margin-top: -12px;
    right: 12px;
    width: 24px;
    height: 24px;

}

.copyInstallLinkDialogMarginTop6Px {
    margin-top: 7px !important;
}

.copyInstallLinkDialogMarginTop16Px {
    margin-top: 16px !important;
}

.copyInstallLinkDialogMarginLeft4Px {
    margin-left: 4px !important;
}