.popOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
  opacity: 1;
  transition: opacity .3s ease;
}

.popOverlayCenter {
  justify-content: center !important;
}

.popOverlayBottom {
  justify-content: flex-end !important;
}

.popFadeIn {
  animation: fadeIn .3s forwards;
}

.popFadeOut {
  animation: fadeOut .3s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.popContentBg {
  position: relative;
  display: flex;
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  z-index: 1001;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  overflow: hidden;
  padding: 16px;
}

/* 弹窗容器 */
.popContent {
  position: relative;
  display: flex;
  background-color: white;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  box-sizing: border-box;
  overflow: hidden;
  padding-bottom: 20px;
}

/* 媒体查询：在小屏幕下应用不同的样式 */
@media screen and (min-width: 770px) {

  /* 弹窗容器 */
  .popContent {
    position: relative;
    display: flex;
    background-color: white;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    width: 393px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: center;
    box-sizing: border-box;
    overflow: hidden;
    padding-bottom: 20px;
  }
}