.asFlowDiagramRoot {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
}

/* 遮罩层 */
.asFlowDiagramOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  z-index: 1000;
  opacity: 1;
  transition: opacity .3s ease;
}

.asFlowDiagramFadeIn {
  animation: fadeIn .3s forwards;
}

.asFlowDiagramFadeOut {
  animation: fadeOut .3s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.asFlowDiagramContentBg {
  position: relative;
  display: flex;
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  z-index: 1001;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  overflow: hidden;
}

/* 弹窗容器 */
.asFlowDiagramContent {
  position: relative;
  display: flex;
  background-color: white;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  box-sizing: border-box;
  overflow: hidden;
  padding: 16px;
}

/* 媒体查询：在小屏幕下应用不同的样式 */
@media screen and (min-width: 770px) {


  /* 弹窗容器 */
  .asFlowDiagramContent {
    position: relative;
    display: flex;
    background-color: white;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    width: 393px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: center;
    box-sizing: border-box;
    overflow: hidden;
    padding: 16px;
  }
}

.global-toast.fade-out {
  opacity: 0;
  /* 设置渐隐至完全透明 */
}

.asFlowDiagramTopMargin40 {
  margin-top: 40px !important;
}

.asFlowDiagramHeight62 {
  height: 62px !important;
}

.asFlowDiagramHeight60 {
  height: 60px !important;
}

.asFlowDiagramHeight40 {
  height: 40px !important;
}

.asFlowDiagramRowStart {
  display: flex;
  width: 100% !important;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;
}

.asFlowDiagramRowEnd {
  display: flex;
  width: 100% !important;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;
}

.asFlowDiagramColStart {
  display: flex;
  width: 100% !important;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
  box-sizing: border-box;
}

.asFlowDiagramShapeTipsCard {
  background-color: #FFF4DE;
  padding: 16px 8px;
  border-radius: 12px;
}

.asFlowDiagramTitleText {
  font-size: 16px;
  color: #054385;
  font-weight: bold;
  text-align: left;
}

.asFlowDiagramTipsText {
  font-size: 12px;
  color: #FF8F28;
  font-weight: bold;
  text-align: left;
}

.asFlowDiagramTipsContent {
  font-size: 12px;
  color: #FF8F28;
  text-align: left;
  margin-top: 8px;
}

.asFlowDiagramFlowIcon {
  width: 100%;
  height: 247px;
  object-fit: contain;
}

.asFlowDiagramActionButtonBg {
  display: flex;
  width: 100%;
  height: 50px;
  margin-top: 10px;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;
}

.asFlowDiagramActionButtonDisable {
  background-color: #DDDEE0 !important;
}

.asFlowDiagramActionButtonDisable:hover {
  background-color: #DDDEE0 !important;
}

.asFlowDiagramActionButtonEnable {
  background-color: #267AF7 !important;
}

.asFlowDiagramActionButtonEnable:hover {
  background-color: #0146AD !important;
}

.asFlowDiagramActionButtonText {
  color: white;
  font-size: 16px;
  font-weight: bold;
}

.asFlowDiagramActionButtonIcon {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.asFlowDiagramActionButtonIconRotate {
  animation: infiniteRotate 1.6s linear infinite !important;
}

.asFlowDiagramNotifyIcon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.asFlowDiagramNotOpenIcon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

@keyframes infiniteRotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.asFlowDiagramNotOpenText {
  display: flex;
  flex: 1 !important;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;
  color: #FF4400;
  font-size: 12px;
  text-align: left;
}

.asFlowDiagramReinstallText {
  text-decoration: underline;
  font-size: 14px;
  font-weight: bold;
}

.asFlowDiagramReinstallTextDisable {
  color: #DDDEE0;
}

.asFlowDiagramReinstallTextEnable {
  color: #267AF7;
}

.asFlowDiagramBottomTipsText {
  display: flex;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 40px;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;
  color: #666666;
  font-size: 12px;
  text-align: left;
}

.asFlowDiagramTopMargin10 {
  margin-top: 10px !important;
}

.asFlowDiagramTopMargin16 {
  margin-top: 16px !important;
}